<template>
    <div class="trucking-report-tool" :class="!hasUserToken ? 'logout-wraper pt-10' : ''" 
		:style="!hasUserToken ? 'background-color: #F1F6FA !important;' : ''">

        <div class="trucking-report-inner-content" v-if="!isError404 && !tokenParamMismatch">
            <div class="d-flex justify-space-between align-center mb-4">
				<h2>Trucking Container Tracker</h2>
				<button class="btn-white" v-if="hasUserToken" @click="shareLink">
					Share Link
					<input type="hidden" id="uniqueToken" :value="getUniqueTokenValue" />
				</button>
			</div>

            <div class="overlay-logout-loading" :class="!hasUserToken && $store.state.reports.isFirstLoading ? 'show' : ''">
                <div class="preloader" v-if="!hasUserToken && $store.state.reports.isFirstLoading">
                    <v-progress-circular
                        :size="40"
                        color="#1A6D9E"
                        indeterminate>
                    </v-progress-circular>
                </div>
            </div>

            <div class="overlay-loading"
                v-bind:class="{
                    'show': getTruckingContainersLoading,
                    'from-logout': !hasUserToken
                }">
                <div class="preloader" v-if="getTruckingContainersLoading">
                    <v-progress-circular
                        :size="40"
                        color="#1A6D9E"
                        indeterminate>
                    </v-progress-circular>
                </div>
            </div>

            <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page="itemsPerPage"
                fixed-header
                class="report-tool-table"
				:footer-props="{
					itemsPerPageOptions:[15, 50, 100, 200, 500, -1]
				}"
				show-select
				v-model="containersSelected">

                <template v-slot:top>
                    <div class="empty-returned-filter d-flex justify-space-between align-center px-4 py-3">
                        <div class="btn-download-selected-containers">
							<v-btn class="btn-white" v-if="containersSelected.length > 0" @click="exportAsExcel">
								<img
									class="mr-1"
									src="@/assets/icons/download.svg"
									width="14px"
									height="14px"
									style="margin-top: 2px;" />
								Download
							</v-btn>
						</div>

                        <div class="d-flex justify-end align-center">
                            <p class="mb-0 mr-3 font-medium">Empty Returned Days</p>
                            <v-text-field
                                placeholder="Enter Days"
								outlined
                                class="text-fields"
                                hide-details="auto"
                                height="40px"
                                @input="onEmptyReturnedDaysChange"
                                v-model="emptyReturnedDays">
                            </v-text-field>
                        </div>
                    </div>
                </template>

				<template v-slot:[`header.data-table-select`]="{ }">
					<div class="d-flex justify-center">
						<v-checkbox
							v-model="isAllSelected"
							hide-details="auto"
							:class="`mt-0 tool-header-checkbox ${checkboxClass}`"
							@click="() => onChangeValue('all', isAllSelected)" />
					</div>
				</template>

				<template v-slot:body="{ items }">
					<tbody>
						<tr v-for="(item, index) in items" :key="index">
							<td class="text-start left-fixed-column">
								<v-checkbox
									v-model="item.isSelected"
									hide-details="auto"
									class="mt-0 tool-item-checkbox"
									@click="() => onChangeValue('single', item.isSelected, item)" />
							</td>

							<td class="text-start left-fixed-column">
								{{ item.shifl_ref }}
							</td>

							<td class="text-start left-fixed-column">
								{{ item.eta }}
							</td>

							<td class="text-start left-fixed-column">
								{{ item.container_number }}
							</td>

							<td class="text-start left-fixed-column">
								{{ item.carrier_name }}
							</td>

							<td> {{ item.container_free_days }} </td>

							<td> {{ item.container_free_until_date }} </td>

							<td> {{ item.pickup_lfd }} </td>

							<td> {{ item.appointment }} </td>

							<td> {{ item.full_out }} </td>

							<td> {{ item.port_wait_time }} </td>

							<td> {{ item.container_empty_notice_at }} </td>

							<td> {{ item.container_empty_notice_day }} </td>

							<td> {{ item.whse_notify_empty_container_day }} </td>

							<td> 
								<p class="truncate-text">
									{{ item.terminal ? item.terminal.name : "-" }}
								</p>
							</td>

							<td> {{ item.return_empty_at }} </td>

							<td>{{ item.emptied_day }}</td>

							<td> {{ item.is_with_per_diem_detention }} </td>

							<td> {{ item.return_appointment_date }} </td>

							<td>{{ item.chassis_day_no }}</td>

							<td> {{ item.date_delivered_whse_at }} </td>

							<td> {{ item.is_pre_pull }} </td>

							<td> {{ item.stop_off_reason }} </td>
						</tr>
					</tbody>
				</template>
            </v-data-table>
        </div>

		<div class="no-data-404-wrapper" v-else>
			<img src="@/assets/images/logo.svg" alt="" v-if="isError404 && !tokenParamMismatch">

            <div class="d-flex align-center justify-center" style="flex-direction: column; height: 100%">
				<img src="@/assets/icons/404-Asset.svg" alt="" width="600px" height="230px" class="mb-6">
				<p style="font-size: 44px; color: #253041;" class="font-medium">Page not found</p>
				<p style="font-size: 16px; color: #4B586C; width: 520px;" class="text-center">
					Sorry, the page you’re trying to access doesn’t exist anymore.
					Please double check the url Or, share the issue with your Shifl account representative.
				</p>
			</div>
        </div>
    </div>
</template>

<script>
import filled from "./util/filled";
import moment from "moment";
import jQuery from "jquery";
import { mapActions, mapGetters } from 'vuex';
import globalMethods from '../../utils/globalMethods';
import _ from "lodash";
import { utils, writeFileXLSX } from 'xlsx';

export default {
    name: "Tool",
    props: [],
    components: { },
    data() {
		return {
			customers: [],
			terminals: [],
			emptyReturnedDays: 15,
			isLoading: false,
			headers: [
				{ text: "", value: "data-table-select", width: "40px", align: "text-start left-fixed-column" },
				{
					text: "Shifl Ref#",
					value: "shifl_ref",
					width: 110,
					fixed: true,
					sortable: true,
                    class: "left-fixed-column"
				},
				{
					text: "ETA",
					value: "eta",
					width: 100,
					fixed: true,
					sortable: true,
                    class: "left-fixed-column"
				},
				{
					text: "Container#",
					value: "container_number",
					fixed: true,
					sortable: true,
                    class: "left-fixed-column",
                    width: 125,
				},
				{
					text: "Carrier",
					value: "carrier_name",
					fixed: true,
					sortable: true,
                    class: "left-fixed-column",
                    width: 100,
				},
				{
					text: "FREE DAYS DETENTION",
					value: "container_free_days",
					width: 100,
                    sortable: false
				},
				{
					text: "CONTAINER FREE UNTIL DATE",
					value: "container_free_until_date",
					width: 165,
                    sortable: false
				},
				{
					text: "LFD",
					value: "pickup_lfd",
					width: 190,
                    sortable: false
				},
				{
					text: "SCHEDULED PICK-UP DATE",
					value: "appointment",
					width: 220,
                    sortable: false
				},
				{
					text: "ACTUAL PICK-UP DATE",
					value: "full_out",
					width: 220,
                    sortable: false
				},
				{
					text: "Port Wait Time",
					value: "port_wait_time",
					width: 220,
                    sortable: false
				},
				{
					text: "Container Empty Notice",
					value: "container_empty_notice_at",
					width: 230,
                    sortable: false
				},
				{
					text: "Container Empty Notice Day",
					value: "container_empty_notice_day",
					width: 180,
                    sortable: false
				},
				{
					text: "Number of Days WHSE Notify Empty Container",
					value: "whse_notify_empty_container_day",
					width: 200,
                    sortable: false
				},
				{
					text: "Return Terminal",
					value: "terminalName",
					width: 290,
                    sortable: false
				},
				{
					text: "Return Empty Date",
					value: "return_empty_at",
					width: 230,
                    sortable: false
				},
				{
					text: "Emptied Day",
					value: "emptied_day",
					width: 100,
                    sortable: false
				},
				{
					text: "With Per Diem Detention",
					value: "is_with_per_diem_detention",
					width: 130,
                    sortable: false
				},
				{
					text: "Return Appointment Date",
					value: "return_appointment_date",
					width: 230,
                    sortable: false
				},
				{
					text: "Total Days Chassis Rental",
					value: "chassis_day_no",
					width: 160,
                    sortable: false
				},
				// {
				// 	text: "Chassis days from Full out till notice",
				// 	value: "chassis_day_full_out_till_notice",
				// 	width: 260
				// },
				// {
				// 	text: "Chassis days from notice till empty return",
				// 	value: "chassis_day_notice_till_empty_in",
				// 	width: 100
				// },
				{
					text: "Date Delivered (to WHSE)",
					value: "date_delivered_whse_at",
					width: 230,
                    sortable: false
				},
				{
					text: "Pre Pull",
					value: "is_pre_pull",
					width: 130,
                    sortable: false
				},
				{
					text: "Stop Off Reason",
					value: "stop_off_reason",
					width: 300,
                    sortable: false
				},
            ],
            containers: [],
			initialFormattedContainers: [],
			changedContainerIds: [],
			isSaving: false,
            itemsPerPage: 10,
            typingTimeout: 0,
            isLogout: false,
			tokenParamMismatch: false,
			containersSelected: [],
			isAllSelected: false
		}
	},
    computed: {
        ...mapGetters({
            getUser: "getUser",
            getTruckingContainers: "getTruckingContainers",
            getTruckingContainersLoading: "getTruckingContainersLoading",
        }),
        isError404() {
            return this.$store.state.reports.truckingError === 404 ? true : false;
        },
        items() {
            if (this.getTruckingContainers.length) {
                return this.getFormattedRows(this.getTruckingContainers)
            } else {
                return []
            }
		},
		shouldSave() {
			let ccIds = this.getUniqueArray(this.changedContainerIds)
			let len = ccIds.length
			for(let i = 0; i < len; i++) {
				if(this.isChanged(this.initialFormattedContainers.find(cntr => cntr.id == ccIds[i]), this.containers.find(cntr => cntr.id == ccIds[i]))) {
					return true
				}
			}
			return false
		},
        selectedCustomer() {
			// check getUser if not null, check default_customer field
			let user = 
				this.getUser !== null 
					? typeof this.getUser.default_customer === "undefined"
						? JSON.parse(this.getUser).default_customer
						: this.getUser.default_customer
					: null;				
			return user ? user : null;
        },
		getUniqueTokenValue() {
			let link = "";
			if (this.selectedCustomer) {
				let token = this.selectedCustomer.trucking_report_token;
				link = `apptest.shifl.com/trucking-container-tracker/uniqT=${token}`;
			}
			return link;
		},
		hasUserToken() {
			return localStorage.getItem("user_token") !== null ? true : false;
		},
		checkboxClass() {
			if (this.items.length === this.containersSelected.length) {
				return "all-selected";
			} else {
				if (this.containersSelected.length !== 0 && this.containersSelected.length !== this.items.length) {
					return "some-selected";
				}
			}
			return "";
		}
    },
    methods: {
        ...mapActions({
            fetchTruckingContainers: "fetchTruckingContainers",
            fetchTruckingContainersLogout: "fetchTruckingContainersLogout"
        }),
        ...globalMethods,
		getFormattedRows(rows) {
            this.containers = rows;

			return rows.map(container => {
				return {
					...container,
					container_empty_notice_day: this.getWeekday(container.container_empty_notice_at),
					whse_notify_empty_container_day: this.getDayDiff(container.container_empty_notice_at, container.date_delivered_whse_at),
					emptied_day: this.getWeekday(container.return_empty_at),
					chassis_day_no: this.getDayDiff(container.full_out, container.return_empty_at),
					days_at_whse: this.getDayDiff(container.date_delivered_whse_at, container.container_empty_notice_at),
					chassis_day_full_out_till_notice: this.getDayDiff(container.full_out, container.container_empty_notice_at),
					chassis_day_notice_till_empty_in: this.getDayDiff(container.return_empty_at, container.container_empty_notice_at),
					container_free_until_date: this.addDays(container.full_out, container.container_free_days),
					pickup_lfd: this.formatDate(container.pickup_lfd),
					appointment: this.formatDate(container.appointment),
					full_out: this.formatDate(container.full_out),
					container_empty_notice_at: this.formatDate(container.container_empty_notice_at),
					return_empty_at: this.formatDate(container.return_empty_at),
					return_appointment_date: this.formatDate(container.return_appointment_date),
					date_delivered_whse_at: this.formatDate(container.date_delivered_whse_at),
					terminalName: container.terminal ? container.terminal.name : "",
					is_pre_pull: container.is_pre_pull === 0 || !container.is_pre_pull ? "No" : "Yes",
					is_with_per_diem_detention: container.is_with_per_diem_detention === 0 || !container.is_with_per_diem_detention ? "No" : "Yes",
					isSelected: false
				}
			});
		},
		handleChange(value, item, key) {
			this.changedContainerIds.push(item.id)
			this.containers = this.containers.map(container => {
				if(container.id == item.id) {
					container[key] = value
				}
				return container
			})
		},
		onCustomerSelect(value) {
			this.selectedCustomer = value
			this.changedContainerIds = []
			this.fetchContainers()
		},
		async fetchContainers() {
            if (this.selectedCustomer) {
                try {
                    this.isLoading = true;
                    await this.fetchTruckingContainers(this.emptyReturnedDays);
                    this.containers = this.getTruckingContainers;
                    this.initialFormattedContainers = this.getFormattedRows(this.containers);
                    this.isLoading = false;      
                } catch(e) {
                    this.isLoading = false;
                }
            }
		},
		getWeekday(date) {
			if(date) {
				// return this.getDateOb(date).toFormat("cccc")
                date = moment(date);
                return date.format('dddd');
			}
			return "-"
		},
		getDayDiff(date1, date2) {
			if(filled(date1) && filled(date2)) {
				// return Math.floor(Math.abs(this.getDateOb(date1).diff(this.getDateOb(date2)).as('days')))
                date1 = moment(date1);
                date2 = moment(date2);
                return Math.abs(date1.diff(date2, 'days'))
			}
			return "-"
		},
		getDateOb(dateString) {
			// let date = DateTime.fromISO(dateString)
			// if(date.invalid) {
			// 	date = DateTime.fromSQL(dateString)
			// }
			// return date
            console.log(dateString);
            return dateString;
		},
		getPerDiemDetention(return_empty_at) {
            console.log(return_empty_at);
        },
		handleBoolChange(event, item, key) {
			let checked = event.target.checked
			event.target.checked = item[key]
			this.handleChange(checked, item, key)
		},
		handleInput(event, item, key) {
			this.handleChange(event.target.value, item, key)
		},
		isChanged(cont1, cont2, keys = [
			'port_wait_time',
			'is_pre_pull',
			'date_delivered_whse_at',
			'container_empty_notice_at',
			'terminal',
			'return_empty_at',
			'stop_off_reason',
			'is_with_per_diem_detention',
			'return_appointment_date',
			'full_out',
			'appointment',
			'pickup_lfd',
			'container_free_days'
		]) {
			if(!(filled(cont1) && filled(cont2))){
				return false
			}
			let len = keys.length
			for(let i = 0; i < len; i++) {
				if(cont1[keys[i]] != cont2[keys[i]]) {
					return true
				}
			}
			return false
		},
		async saveChanges() {
			this.isSaving = true
			// Nova.request().post('/nova-vendor/trucking-report-tool/save', {
			// 	customer_id: this.selectedCustomer.id,
			// 	containers: this.getChangedContainers().map(cntr => {
			// 		return {
			// 			id: cntr.id,
			// 			trucking_details: {
			// 				port_wait_time: cntr.port_wait_time,
			// 				is_pre_pull: cntr.is_pre_pull,
			// 				date_delivered_whse_at: cntr.date_delivered_whse_at,
			// 				terminal_id: cntr.terminal_id,
			// 				return_empty_at: cntr.return_empty_at,
			// 				stop_off_reason: cntr.stop_off_reason,
			// 				terminal_id: cntr.terminal?.id,
			// 				is_with_per_diem_detention: cntr.is_with_per_diem_detention,
			// 				return_appointment_date: cntr.return_appointment_date,
			// 				lfd: cntr.pickup_lfd,
			// 				scheduled_pickup_at: cntr.appointment,
			// 				full_out: cntr.full_out,
			// 			}
			// 		}
			// 	}),
			// 	shipments: this.getFormattedShipments(this.getChangedContainers(['container_free_days', 'container_empty_notice_at']))
			// }).then(res => {
			// 	Nova.success("Successfully Saved changes!")
			// 	this.fetchContainers()
			// 	this.isSaving = false
			// }).catch(err => {
			// 	this.isSaving = false
			// })
		},
		getChangedContainers(keys = []) {
			let ccIds = this.getUniqueArray(this.changedContainerIds)
			let len = ccIds.length
			let changedContainers = []
			for(let i = 0; i < len; i++) {
				let initCont = this.initialFormattedContainers.find(cntr => cntr.id == ccIds[i])
				let cont = this.containers.find(cntr => cntr.id == ccIds[i])
				if((keys.length && this.isChanged(initCont, cont, keys)) || (keys.length == 0 && this.isChanged(initCont, cont))) {
					changedContainers.push(cont)
				}
			}
			return changedContainers
		},
		getUniqueArray(arr) {
			return [...new Set(arr)]
		},
		handleSelect(value, item, key){
			this.handleChange(value, item, key)
		},
		addDays(date, days){
			if(date && days) {
                date = moment(date);
                return moment(date, "M/DD/YYYY").add(days, 'days').format("M/D/YYYY hh:mm A");
			}
			return null
		},
		cancelSave(){
			this.fetchContainers()
		},
		getFormattedShipments(containers){
			let shipments = [];
			containers.map(cntr => {
				if(!filled(shipments[cntr.shipment_id])){
					shipments[cntr.shipment_id] = {
						shipment_id: cntr.shipment_id,
						container_free_days: cntr.container_free_days,
						containers: []
					}
				}
				shipments[cntr.shipment_id].containers.push({
					id: cntr.id,
					uuid: cntr.uuid,
					shipment_id: cntr.shipment_id,
					number: cntr.number,
					trucking_details: {
						'container_empty_notice_at' : cntr.container_empty_notice_at
					}
				})
			})
			return {...shipments}
		},
		onEmptyReturnedDaysChange() {
			clearTimeout(this.typingTimeout);
			this.typingTimeout = setTimeout(() => {
				let emptyReturnedDays = this.emptyReturnedDays;
				this.emptyReturnedDays = 0;

				this.isAllSelected = false;
				this.containersSelected = [];

				this.$nextTick(() => {
					if (emptyReturnedDays !== "") {
						this.emptyReturnedDays = parseInt(emptyReturnedDays)
						if (this.emptyReturnedDays < 0) {
							this.notificationError("Days count can't be negative!");
							this.emptyReturnedDays = 0;
						} else if (this.emptyReturnedDays > 180) {
							this.notificationError("Empty Returned Days can't be more than 180 days!");
							this.emptyReturnedDays = 180;
						} else if (this.emptyReturnedDays === "") {
							this.emptyReturnedDays = 15;
							this.notificationError("Days shouldn't be empty!");        
						} else {
							if (this.isLogout) {
								let token = this.$route.params.token;
								let data = {
									token,
									emptyReturnedDays: this.emptyReturnedDays
								}
								this.fetchTruckingContainersLogout(data);
							}  else {
								this.fetchContainers();
							}
						}
					} else {
						this.notificationError("Days shouldn't be empty!");
						this.emptyReturnedDays = 15;
					}
				})
			}, 500)
		},
        formatDate(date) {
            return date ? moment(date).format("MM/DD/YYYY hh:mm A") : "-";
        },
        handleUpliftScroll(e) {
            let targetScrollLeft = e.target.scrollLeft;
            // let tableData = jQuery('.report-tool-table table tbody tr');
            // Object.values(tableData).forEach(element => {
            //     let cells = element.cells;                    
            //     if (cells !== undefined) {
            //         for (let index = 0; index < 4; index++) {
            //             var cell = cells[index];
            //             cell.classList.add("left-fixed-column");
            //         }
            //     }
            // });

            if (targetScrollLeft > 0) {                
                jQuery('.left-fixed-column').addClass('show-box-shadow');
            } else {
                jQuery('.left-fixed-column').removeClass('show-box-shadow');
            }
        },
        checkWindowTruckingToken() {
            if (Object.keys(this.$route.params).length > 0) {
                this.isLogout = true;
            } else this.isLogout = false;
        },
		shareLink() {
			let testingCodeToCopy = document.querySelector("#uniqueToken");
			testingCodeToCopy.setAttribute("type", "value");
			testingCodeToCopy.select();
			if (document.execCommand("copy")) {
				this.notificationMessage("Link copied!")
			}

			/* unselect the range */
			testingCodeToCopy.setAttribute("type", "hidden");
			window.getSelection().removeAllRanges();
		},
		onChangeValue(isFor, selectValue, item) {
			if (isFor === "single") {
				let findId = _.findIndex(this.containersSelected, e => (e.id === item.id));
				item.isSelected = selectValue;

				if (selectValue) {
					if (findId === -1) this.containersSelected.push(item);
				} else {
					this.containersSelected = this.containersSelected.filter(v => {
						return v.id !== item.id;
					})
				}
			} else {
				if (selectValue) {
					this.containersSelected = this.items;
				} else this.containersSelected = [];

				this.items.forEach(item => {
					item.isSelected = selectValue;
				});	
			}
		},
		exportAsExcel() {
            let processedData = this.processDataToJson(this.containersSelected);
            // let dateTime = moment(new Date()).format("MM-DD-YYYY_HH:mm:ss");
			let dateTime = moment(new Date()).format("x");
			let customerName = this.selectedCustomer !== null ?
				this.selectedCustomer.company_name : "";

			let name = `Trucking_Report_${customerName}_${dateTime}.xlsx`;

            const ws = utils.json_to_sheet(processedData);
            ws['!cols'] = this.fitToColumn(processedData);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Data");
            writeFileXLSX(wb, name);
        },
		processDataToJson(data) {
            if (data !== null) {
                let jsonArray = [];
				data.map(v => {
					let dataObj = {};
					this.headers.map((header) => {
						dataObj[header.text] = v[header.value];
					})
					jsonArray.push(dataObj)
				});
                return jsonArray;
            }
        },
        fitToColumn(data) {
            const columnWidths = [];    
            for (const property in data[0]) {
                columnWidths.push({        
                    wch: Math.max(        
                        property ? property.toString().length : 0,         
                        ...data.map(obj =>           
                            obj[property] ? obj[property].toString().length : 0         
                    ))      
                });   
            }   
            return columnWidths; 
        },
	},
    mounted() {
		if (this.hasUserToken) {
			let tokenParams = this.$route.params.token;
			let customerToken = this.selectedCustomer.trucking_report_token;

			if (tokenParams === customerToken) {
				this.fetchContainers();
				this.checkWindowTruckingToken();
				this.tokenParamMismatch = false;
			} else {
				this.tokenParamMismatch = true;
			}
		}

		setTimeout(() => {            
			const tableWrapper = document.querySelector('.v-data-table__wrapper');
			tableWrapper.addEventListener('scroll', this.handleUpliftScroll);
		}, 500);
    },
    updated() {
        this.checkWindowTruckingToken();
    }
}
</script>

<style lang="scss">
@import "./scss/tool.scss";
</style>