<!-- @format -->

<template>
	<div class="SignIn">
		<div class="BG#FFF login-wrapper" v-if="!loginSuccess">
			<img src="@/assets/images/logo.svg" alt="" class="logo" />

			<v-form ref="form" v-model="valid" @submit.prevent="submit">
				<h2 class="headLogIn">Sign In to shifl</h2>


				<v-container>
					<v-container class="payment-message-container" v-if="makePaymentInfo">
						<img class="make-payment-info-icon" src="@/assets/images/make-payment-info-icon.svg" alt="" />
						<p class="make-payment-info-message"> To make the payment, please log in to your Shifl account. If
							you are new to the platform, please
							sign up.</p>
					</v-container>


					<v-row>
						<v-col cols="12" md="12">
							<small>EMAIL ADDRESS</small>
							<v-text-field placeholder="e.g. abcdefghij@email.com" filled v-model="form.email"
								:rules="emailRules" required hide-details="auto"></v-text-field>
						</v-col>

						<v-col cols="12" sm="12">
							<small>PASSWORD</small>
							<v-text-field placeholder="Type your password" filled v-model="form.password"
								:append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]"
								:type="show1 ? 'text' : 'password'" name="input-10-1" hint="" @click:append="show1 = !show1"
								hide-details="auto"></v-text-field>

							<!-- <v-text-field
                                placeholder="Type your password"
                                filled
                                v-model="form.password"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.min]"
                                :type="show1 ? 'text' : 'password'"
                                name="input-10-1"
                                label="Password"
                                hint=""
                                counter -- show how many characters of facebook
                                @click:append="show1 = !show1"
                            ></v-text-field> -->
						</v-col>

						<v-col cols="12" class="checkbox-wrapper">
							<v-col class="checkboxStyle py-0" cols="6" sm="6">
								<v-checkbox v-model="form.checkbox" label="Remember me" hide-details="auto"></v-checkbox>
							</v-col>

							<v-col cols="6" sm="6" class="LinkForgetPass py-0">
								<router-link to="/forgetPassword">Forgot Password?</router-link>
							</v-col>
						</v-col>

						<v-col cols="12" sm="12">
							<v-btn class="submitFormBtn mb-0" text type="submit">
								{{ form.loginBtnValue }}
							</v-btn>
						</v-col>

						<!-- <v-col cols="12" sm="12">
                            <v-btn href="https://shifl.com/register" class="submitFormBtn" text>Sign Up</v-btn>
                        </v-col> -->

						<v-col v-show="errorMessageResponse !== ''" cols="12" sm="12" class="flex pb-0">
							<label class="error-message">
								<img src="@/assets/images/error-alert.svg" alt="" />
								<!-- <span class="error-text">Sorry credential doesn’t match. Either email address or
									password is incorrect.</span> -->

								<span class="error-text font-medium"> {{ errorMessageResponse }} </span>
							</label>
						</v-col>
					</v-row>
				</v-container>
			</v-form>
			<div class="snippet signup">
				<div class="stage">
					<span class="ask-account-text">Don’t have a sign in credential?
						<a class="sign-text"  @click="handleLinkClick"
							>Request</a>
					</span>
				</div>
			</div>
		</div>

		<div class="snippet" v-if="loginSuccess">
			<div class="stage">
				<div class="dot-collision"></div>
			</div>
		</div>

		<div>
			<LoginToMakePaymentDialogVue
				:dialogShow="makePaymentInfoDialog"
				@close="closeLogInToMakePaymentDialog"
				:statementId="statementId"
			/>
			<LoginCredentialsRequestSentDialog
				:dialogShow="requestSentDialog"
				@close="closeRequestSentDialog"
			/>
		</div>

		<!-- switch account dialog -->
		<ConfirmDialog :dialogData.sync="showLoginDialog">
            <template v-slot:dialog_icon>
				<div class="header-wrapper-close">
                    <img src="@/assets/icons/info-blue.svg" alt="alert">
                </div>
			</template>

			<template v-slot:dialog_title>
				<h2> Login with your Shifl Account? </h2>
			</template>

			<template v-slot:dialog_content>
				<div class="switch-account-modal-wrapper">
					<p> Do you want to login to your Shifl account to proceed with  documents upload? </p>
				</div>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" @click="stayToPage">
					Login
				</v-btn>

				<v-btn class="btn-white" @click="goToUpload">
					Upload without Login
				</v-btn>
			</template>
		</ConfirmDialog>
		
		<!-- if request has been cancelled -->
		<ConfirmDialog :dialogData.sync="requestCancelledConfirmationDialog">
            <template v-slot:dialog_icon>
				<div class="header-wrapper-close">
                    <img src="@/assets/icons/alert.svg" alt="alert">
                </div>
			</template>

			<template v-slot:dialog_title>
				<h2> Request has been cancelled </h2>
			</template>

			<template v-slot:dialog_content>
				<p> {{ supplierCompanyName || "" }} has cancelled the request for document upload. </p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" @click="requestCancelInfoClose('requestCancelledConfirmationDialog')">
					Understood
				</v-btn>
			</template>
		</ConfirmDialog>

		<!-- if request has been fulfilled -->
		<ConfirmDialog :dialogData.sync="requestFulfilledConfirmationDialog" :className="'request-small-dialog'">
            <template v-slot:dialog_icon>
				<div class="header-wrapper-close">
                    <img src="@/assets/icons/info-blue.svg" alt="alert">
                </div>
			</template>

			<template v-slot:dialog_title>
				<h2> Request has been fulfilled </h2>
			</template>

			<template v-slot:dialog_content>
				<p> This request has already been fulfilled. </p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" @click="requestCancelInfoClose('requestFulfilledConfirmationDialog')">
					Understood
				</v-btn>
			</template>
		</ConfirmDialog>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoginToMakePaymentDialogVue from "../components/Login/LoginToMakePaymentDialog.vue";
import LoginCredentialsRequestSentDialog from "../components/Login/LoginCredentialsRequestSentDialog.vue";
import ConfirmDialog from "@/components/Dialog/GlobalDialog/ConfirmDialog.vue";
import axios from 'axios';
const baseURL = process.env.VUE_APP_BASE_URL;
import globalMethods from '../utils/globalMethods';

export default {
	components: {
		LoginToMakePaymentDialogVue,
		LoginCredentialsRequestSentDialog,
		ConfirmDialog
	},
	data: () => ({
		valid: true,
		show1: false,
		form: {
			email:
				localStorage.getItem("shifl.user_rememberme_email") !== null
					? localStorage.getItem("shifl.user_rememberme_email")
					: "",
			password: "",
			checkbox:
				localStorage.getItem("shifl.user_rememberme_email") !== null
					? true
					: false,
			loading: false,
			loginBtnValue: "Sign In",
			loginBtnDefaultValue: "Sign In",
			loginBtnLoadingValue: "Signing in...",
		},
		errors: [],
		rules: {
			required: (value) => !!value || "Password is required.",
			min: (v) => v.length >= 1 || "Password must be valid",
			emailMatch: () => `Please provide a valid email address`,
		},
		emailRules: [
			(v) => !!v || "E-mail is required.",
			(v) => /.+@.+/.test(v) || "E-mail must be valid",
		],
		loginSuccess: false,
		makePaymentInfoDialog: false,
		makePaymentInfo: false,
		statementId: 0,
		companyName: "",
		requestSentDialog: false,
		showLoginDialog: false,
		requestCancelledConfirmationDialog: false,
		supplierCompanyName: "",
		requestFulfilledConfirmationDialog: false
	}),
	computed: {
		...mapGetters(["getErrorMessage"]),
		errorMessageResponse() {
			let error = "";
			if (this.getErrorMessage !== "") {
				if (this.getErrorMessage === "This combination does not exists." || 
					this.getErrorMessage === "Trying to get property 'id' of non-object" ||
					this.getErrorMessage === "Wrong username or password.") {
					error = "Sorry credential doesn’t match. Either email address or password is incorrect."
				} else {
					error = this.getErrorMessage;
				}
			}
			return error;
		}
	},
	methods: {
		...mapActions(["login", "setErrorMessageEmpty"]),
		...globalMethods,
		handleLinkClick() {
			window.location.href =
				window.location.origin + `/signup?statementId=${this.statementId}&companyName=${this.companyName}`;
		},
		async submit() {
			this.$refs.form.validate();

			if (this.$refs.form.validate()) {
				//make sure to lock the login button to prevent multiple login clicks
				if (!this.form.loading) {
					//set loading state
					this.form.loading = true;
					this.form.loginBtnValue = this.form.loginBtnLoadingValue;

					//attempt to login
					try {
						let redirectTo =
							this.$route.query && this.$route.query.redirectTo
								? this.$route.query.redirectTo
								: "";
						this.form.redirectTo = redirectTo;
						await this.login(this.form);
						this.form.loading = false;
						this.form.loginBtnValue = this.form.loginBtnDefaultValue;

						if (this.getErrorMessage == "") {
							this.loginSuccess = true;
						} else {
							this.loginSuccess = false;
						}
					} catch (e) {
						this.form.loading = false;
						this.form.loginBtnValue = this.form.loginBtnDefaultValue;
						this.loginSuccess = false;
					}
				}
			}
		},
		closeLogInToMakePaymentDialog(openRequestSentDialog = false) {
			this.makePaymentInfoDialog = false;
			if (openRequestSentDialog) {
				this.requestSentDialog = true;
			}
		},
		closeRequestSentDialog() {
			this.requestSentDialog = false;
		},
		stayToPage() { // nothing happens, just close the modal
			this.showLoginDialog = false;
			localStorage.removeItem("showLoadingDialog")
		},
		goToUpload() { // go to logged out upload
			localStorage.removeItem("showLoadingDialog")
			let location = window.location
			let newUrl = `${location.origin}/logged-out-upload${location.search}`
			window.location.href = newUrl;
		},
		async checkForCancelDocument(id, companyName) {
			const url = new URL(
				window.location.origin + "/" + this.$route.query.redirectTo
			);

            await axios.get(`${baseURL}/no-login-check-for-cancel/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    if (!res.data.status) {
						const toUploadingPage = url.searchParams.get("toUploadingPage");

						if (toUploadingPage === null) {
							localStorage.setItem("showLoadingDialog", true)

							setTimeout(() => {
								let show = localStorage.getItem("showLoadingDialog")
								this.showLoginDialog = show === "true" || show ? true : false;
							}, 500);
						} else {
							this.goToUpload();
						}
                    } else {
                        let message = res.data.message

						if (message === "cancelled") {
							this.requestCancelledConfirmationDialog = true;
							this.supplierCompanyName = companyName;
							this.requestFulfilledConfirmationDialog = false;
						} else {
							this.requestFulfilledConfirmationDialog = true;
							this.requestCancelledConfirmationDialog = false;
						}
                    }
                }
            }).catch(() => {
				this.notificationError("An error occurred.")
            })
        },
		requestCancelInfoClose(dataItem) {
            this[dataItem] = false;
			this.stayToPage();
			this.supplierCompanyName = "";
			this.$router.push({ query: '' }).catch(()=>{});
			// this.$router.push("/login");
        },
	},
	watch: {
		"form.email": {
            handler: function (value) {
				if (value) {
					this.setErrorMessageEmpty("");
				}
            },
            deep: true
        },
		"form.password": {
            handler: function (value) {
				if (value) {
					this.setErrorMessageEmpty("");
				}
            },
            deep: true
        },
	},
	mounted() {
		if (this.$route.query && this.$route.query.redirectTo) {
			const url = new URL(
				window.location.origin + "/" + this.$route.query.redirectTo
			);
			const action = url.searchParams.get("action");
			const statementId = url.searchParams.get("statementId");
			const companyName = url.searchParams.get("companyName");

			if (action === "makePayment" && statementId != "") {
				// this.makePaymentInfoDialog = true;
				this.makePaymentInfo = true;
				this.statementId = statementId;
				this.companyName = companyName;
			} else {
				const fromMail = url.searchParams.get("from");
				const vendorId = url.searchParams.get("scustomer");
				const requestId = url.searchParams.get("rid");
				const companyName = url.searchParams.get("companyName");
				// const type = url.searchParams.get("type");
				// const isFromLoggedIn = url.searchParams.get("isFromLoggedIn");

				if (fromMail === "mail" && vendorId !== null) { // && isFromLoggedIn === null					
					this.checkForCancelDocument(requestId, companyName);
				}
			}
		}
	},
	updated() { },
};
</script>

<style>
.login-wrapper .error-message {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.login-wrapper .error-message img {
	margin-top: 3px;
}

.login-wrapper .error-message .error-text {
	font-size: 11px;
	text-align: left;
	color: #F93131;
	padding-left: 10px;
	margin-bottom: 10px;
}

.login-wrapper .LinkForgetPass a {
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #0171a1;
	text-decoration: none;
}

.login-wrapper .LinkForgetPass {
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	/* padding-top: 0; */
	text-align: right;
}

.login-wrapper .checkboxStyle .v-input--selection-controls {
	margin-top: 0;
	padding-top: 0;
}

.login-wrapper .submitFormBtn span {
	font-family: "Inter-Regular", sans-serif !important;
}

/* .login-wrapper .col-6,
.login-wrapper .col-12:not(:last-of-type) {
    padding-bottom: 0;
} */

.dot-collision {
	position: relative;
	width: 20px;
	height: 20px;
	border-radius: 25px;
	background-color: #9880ff;
	color: #9880ff;
}

.dot-collision::before,
.dot-collision::after {
	content: "";
	display: inline-block;
	position: absolute;
	top: 0;
}

.dot-collision::before {
	left: -20px;
	width: 20px;
	height: 20px;
	border-radius: 25px;
	background-color: #9880ff;
	color: #9880ff;
	animation: dotCollisionBefore 2s infinite ease-in;
}

.dot-collision::after {
	left: 20px;
	width: 20px;
	height: 20px;
	border-radius: 25px;
	background-color: #9880ff;
	color: #9880ff;
	animation: dotCollisionAfter 2s infinite ease-in;
	animation-delay: 1s;
}

@keyframes dotCollisionBefore {

	0%,
	50%,
	75%,
	100% {
		transform: translateX(0);
	}

	25% {
		transform: translateX(-15px);
	}
}

@keyframes dotCollisionAfter {

	0%,
	50%,
	75%,
	100% {
		transform: translateX(0);
	}

	25% {
		transform: translateX(15px);
	}
}

.signup {
	/* margin-top: 23px; */
	/* Frame 99 */

	/* Auto Layout */
	/* display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    position: relative;
    width: 218px;
    height: 20px;
    left: 611px;
    top: 668px; */

	margin-top: 32px;
}

.sign-text {
	/* Sign Up */
	text-decoration: none;
	position: static;
	height: 20px;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	text-align: right;

	/* Dark Blue */
	color: #0171a1;

	/* Inside Auto Layout */
	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 0px 2px;
}

.ask-account-text {
	/* Don’t have an account? */

	position: static;
	width: 157px;
	height: 20px;
	left: 0px;
	top: 0px;

	/* H5 - 14M */
	/* font-family: Inter;
    font-style: normal; */
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height, or 143% */
	text-align: right;

	/* Text Black */
	color: #253041;

	/* Inside Auto Layout */
	flex: none;
	order: 0;
	flex-grow: 0;
	margin: 0px 8px;
}


.payment-message-container {
	background-color: #FFEFD6;
	text-align: start;
	display: flex;
	justify-content: center;
	margin-bottom: 24px;
}


.make-payment-info-message {
	padding: 0px !important;
	margin: 0px !important;
	font-size: 14px;
}


.make-payment-info-icon {
	width: 20px;
	height: 20px;
	margin-right: 4px;

}
</style>
